import React, { FC, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { getUserProfileCardData } from './queries/getUserProfileCardData';
import { T2ProfileCard } from './T2ProfileCard';
import { ProfileCardLoader } from './ProfileCardLoader';
import { getMutualTerritories } from './queries/getMutualTerritories';
import { useReactiveAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { useT2Query } from '../../hooks/useT2Query';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';

export type TProfileCardContainerProps = {
  id: string;
};

export const T2ProfileCardContainer: FC<TProfileCardContainerProps> = ({ id }) => {
  const { data: loggedInUser, isAuthenticated } = useReactiveAuthenticatedUser();
  const { sendEvent } = useAnalytics();

  const isCurrentlyLoggedInUser = !!loggedInUser?.user?.id && loggedInUser?.user?.id === id;

  const [getT2UserProfileData, { data: t2User, loading: t2UserLoading }] = useLazyQuery(getUserProfileCardData, {
    variables: { userId: id },
  });

  const { data: mutualTerritoriesData, loading: mutualTerritoriesLoading } = useT2Query(getMutualTerritories, {
    variables: { id1: loggedInUser?.user?.id, id2: id },
    skip: isCurrentlyLoggedInUser || !isAuthenticated,
  });

  useEffect(() => {
    getT2UserProfileData();
  }, [id]);

  const user = { ...t2User?.user, territories: mutualTerritoriesData?.territories || [] };

  if (t2UserLoading || mutualTerritoriesLoading) {
    return <ProfileCardLoader />;
  }

  const sendUserProfileCardViewAnalytics = () => {
    sendEvent('user_profile_card_view', { user_id: id });
  };

  return (
    <T2ProfileCard
      user={user}
      isCurrentlyLoggedInUser={isCurrentlyLoggedInUser}
      onClick={sendUserProfileCardViewAnalytics}
    />
  );
};
