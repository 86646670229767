import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import { UserDetail } from '../UserDetail/UserDetail';
import getUserDisplayName from '../../../utils/getUserDisplayName';
import { getUserPageUrl } from '../../services/pagesUrl/getUserPageUrl';
import { TAvatarSize } from '../UserProfileImage/types';
import { TUserDetailTextSize } from '../UserDetail/types';

export interface IAuthorProps {
  name?: string;
  username: string;
  profileImage?: TCloudinaryImageShape;
  profileImageSize?: TAvatarSize;
  publicAddress?: string;
  shouldDisplayNameOnDesktopDevices?: boolean;
  onClick?: () => void;
  textSize?: TUserDetailTextSize;
}

const AuthorDetail: React.FC<IAuthorProps> = ({
  name,
  profileImage,
  username,
  profileImageSize,
  shouldDisplayNameOnDesktopDevices,
  onClick,
  textSize,
}) => (
  <UserDetail
    onClick={onClick}
    href={getUserPageUrl(username)}
    displayName={getUserDisplayName({ name, username })}
    profileImage={profileImage!}
    size={profileImageSize}
    shouldDisplayNameOnDesktopDevices={shouldDisplayNameOnDesktopDevices}
    textSize={textSize}
  />
);

export default AuthorDetail;
