import cx from 'classnames';
import { HTMLProps, ReactNode } from 'react';

type TProps = HTMLProps<HTMLDivElement> & { children: ReactNode };

export const StackAvatar = ({ className, ...props }: TProps) => (
  <div
    className={cx('relative rounded-full w-min border-2 border-white-100 bg-white-100 z-0 hover:!z-10', className)}
    {...props}
  />
);
