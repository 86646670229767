import { ReactNode, FC } from 'react';
import cx from 'classnames';

type TStackProps = { items: TItem[] };

type TItem = {
  key: string;
  content: ReactNode;
};

export const Stack: FC<TStackProps> = ({ items }) => (
  <ul className="list-none flex flex-row items-center">
    {items.map(({ content, key }) => (
      <li className={cx('[&:not(:first-of-type)]:ml-[-10px]')} key={key}>
        {content}
      </li>
    ))}
  </ul>
);
